.MuiTableRow-root {
    vertical-align: top !important;
}
.MuiTableCell-root:first-child {
    padding-left: 16px;
}
.MuiTableCell-root:last-child {
    padding-right: 16px;
}
@media (min-width: 600px) {
    .MuiTableCell-root:first-child {
        padding-left: 24px;
    }
    .MuiTableCell-root:last-child {
        padding-right: 24px;
    }
}
.MuiTableCell-root > .MuiIconButton-root {
    margin-top: -12px;
    margin-bottom: -12px;
}
.MuiTableCell-root > .MuiIconButton-root:not(:focus) {
    opacity: 0;
    pointer-events: none;
}
.MuiTableCell-root:hover > .MuiIconButton-root {
    opacity: 1;
    pointer-events: auto;
}
