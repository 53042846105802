html {
    height: 100%;
}
body {
    min-height: 100%;
    display: flex;
}
#root {
    display: flex;
    flex: 1;
}
